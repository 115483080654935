export default [
  {
    path: '/user/user-list',
    name: 'user-list',
    component: () => import('@/views/user/user-list/UserList.vue'),
    meta: {
      Permission: 'users_page_menu',
    },
  },

  {
    path: '/user/user-edit/:id',
    name: 'user-edit',
    component: () => import('@/views/user/user-add/UserEdit.vue'),
    meta: {
      navActiveLink: 'user-list',
      Permission: 'users_page_menu',
    },
  },

  {
    path: '/user/user-add',
    name: 'user-add',
    component: () => import('@/views/user/user-add/UserAdd.vue'),
    meta: {
      navActiveLink: 'user-list',
      Permission: 'users_page_menu',
    },
  },

  {
    path: '/user/user-profile',
    name: 'user-profile',
    component: () => import('@/views/user/user-profile/AccountSetting.vue'),
    meta: {
      Permission: '',
    },
  },
  {
    path: '/user/user-preview/:id',
    name: 'user-preview',
    component: () => import('@/views/user/user-preview/UserView.vue'),
    meta: {
      Permission: 'users_page_menu',
    },
  },
];
