import { $themeBreakpoints } from '@themeConfig';

export default {
  namespaced: true,

  state: {
    // baseURL: 'http://127.0.0.1:8000/storage/images/',
    // profileURL: 'http://127.0.0.1:8000/storage/profile/',
    // fileURL: 'http://127.0.0.1:8000/storage/files/',
    // excelFileURL: 'http://127.0.0.1:8000/storage/excel_files/',
    // settingURL: 'http://127.0.0.1:8000/storage/settings/',
    // leadFileURL: 'http://127.0.0.1:8000/storage/Leads/',
    // portfolioFileURL: 'http://127.0.0.1:8000/storage/portfolio/',
    // companiesURL: 'http://127.0.0.1:8000/storage/company/',

    baseURL: 'https://api.planning.systemstailormade.com/storage/app/public/images/',
    profileURL: 'https://api.planning.systemstailormade.com/storage/app/public/profile/',
    fileURL: 'https://api.planning.systemstailormade.com/storage/app/public/files/',
    excelFileURL: 'https://api.planning.systemstailormade.com/storage/app/public/excel_files/',
    settingURL: 'https://api.planning.systemstailormade.com/storage/app/public/settings/',
    leadFileURL: 'https://api.planning.systemstailormade.com/storage/app/public/Leads/',
    portfolioFileURL: 'https://api.planning.systemstailormade.com/storage/app/public/portfolio/',
    companiesURL: 'https://api.planning.systemstailormade.com/storage/app/public/company/',

    // baseURL: 'https://api.rotagenerator.systemstailormade.com/storage/app/public/images/',
    // profileURL: 'https://api.rotagenerator.systemstailormade.com/storage/app/public/profile/',
    // fileURL: 'https://api.rotagenerator.systemstailormade.com/storage/app/public/files/',
    // excelFileURL: 'https://api.rotagenerator.systemstailormade.com/storage/app/public/excel_files/',
    // settingURL: 'https://api.rotagenerator.systemstailormade.com/storage/app/public/settings/',
    // leadFileURL: 'https://api.rotagenerator.systemstailormade.com/storage/app/public/Leads/',
    // portfolioFileURL: 'https://api.rotagenerator.systemstailormade.com/storage/app/public/portfolio/',
    // companiesURL: 'https://api.rotagenerator.systemstailormade.com/storage/app/public/company/',


    windowWidth: 0,
    shallShowOverlay: false,
    token: '',
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return 'xl';
      if (windowWidth >= $themeBreakpoints.lg) return 'lg';
      if (windowWidth >= $themeBreakpoints.md) return 'md';
      if (windowWidth >= $themeBreakpoints.sm) return 'sm';
      return 'xs';
    },

    isAuthenticated(state) {
      return state.token !== '';
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
    },
  },
  actions: {},
};
